import { createSlice } from '@reduxjs/toolkit';

import { NewsM} from '../models/newsModels';

const initialState: NewsM = {
    news_data: [],
    section_one_title: undefined,
    section_one_subtitle: undefined,
    section_one_img: undefined,

    section_two_title: undefined,
    section_two_subtitle: undefined,
    section_two_raw_content: [],

    section_three_title: undefined,
    section_three_raw_content: [],

    section_four_title: undefined,
    section_four_raw_content: []
  }

  function selectField(state: any, obj: any){
    return state[obj.tag] = obj
}

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setData: (state, action ) => {
        state.news_data = action.payload
        action.payload.map((obj: any) => {
            selectField(state, obj);
        })
    },

    setCardsData: (state, action) => {
        action.payload.map((obj: any) => {
            if(obj.tag === 'section_two_raw_content'){
                state.section_two_raw_content.push(obj)
            }else if(obj.tag === 'section_three_raw_content'){
                state.section_three_raw_content.push(obj)
            }else if(obj.tag === 'section_four_raw_content'){
                state.section_four_raw_content.push(obj)
            }
        })
    },

    reset: () => initialState
  }
});

export const { setData, setCardsData, reset } = newsSlice.actions;


export default newsSlice.reducer;