import { createSlice } from '@reduxjs/toolkit';

import { VendorsM } from '../models/vendorsModel';

const initialState: VendorsM = {
    vendors_data: [],
    section_one_title: undefined,
    section_one_subtitle: undefined,
    section_one_img: undefined,

    section_two_title: undefined,
    section_two_column_content: [],
    section_two_raw_content: [],

    section_three_title: undefined,
    section_three_column_content: [],
    section_three_raw_content: []
  }

  function selectField(state: any, obj: any){
    if(state[obj.tag] != undefined){
        state[obj.tag] = undefined;
    }
    return state[obj.tag] = obj
}

export const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    setData: (state, action ) => {
        state.vendors_data = action.payload
        action.payload.map((obj: any) => {
            if(obj.tag === 'section_two_column_content'){
                state.section_two_column_content.push(obj)
            }else if(obj.tag === 'section_three_column_content'){
                state.section_three_column_content.push(obj)
            }else{
                selectField(state, obj);
            }
        })
    },

    setCardsData: (state, action) => {
        action.payload.map((obj: any) => {
            if(obj.tag === 'section_two_raw_content'){
                state.section_two_raw_content.push(obj)
            }else if(obj.tag === 'section_three_raw_content'){
                state.section_three_raw_content.push(obj)
            }
        })
    },

    reset: () => initialState
  }
});

export const { setData, setCardsData, reset } = vendorsSlice.actions;


export default vendorsSlice.reducer;