import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import Footer from '../features/footerSlice';
import counterReducer from '../features/counter/counterSlice';
import headerSlice from '../features/headerSlice';
import langSlice from '../features/langSlice';
import mainPSlice from '../features/mainPSlice';
import vendorsSlice from '../features/vendorsSlice';
import ownersSlice from '../features/ownersSlice';
import serviceSlice from '../features/serviceSlice';
import newsSlice from '../features/newsSlice';
import oneNewsSlice from '../features/oneNewsSlice';
import devsSlice from '../features/devsSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    header: headerSlice,
    langs: langSlice,
    mainp: mainPSlice,
    footer: Footer,
    vendors: vendorsSlice,
    owners: ownersSlice,
    service: serviceSlice,
    news: newsSlice,
    one_news: oneNewsSlice,
    devs: devsSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
