import React from 'react';
import API from '../app/api';
import 'bulma/css/bulma.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { setData, clearState } from '../features/mainPSlice';
import { MainPage } from '../models/mainPModel';
import arrow from '../static/arrow.svg';
import yellow_arrow from '../static/yellow_arrow.png';
import unios_slider from '../static/unios_slider.png';
import section_one_img from '../static/section_one_img.png'
import section_two_img from '../static/section_two_img.png'
import section_three_img from '../static/section_three_img.png';
import section_four_img from '../static/section_four_img.png';
import { Helmet } from "react-helmet";

function getMainPageData(d: any, lang: any){
    API.get(`api/data/?lang=${lang.id}&page=main`).then(
        res => {
            d(clearState());
            d(setData(res.data));
        }
    )
}

function SectionOne(mi: MainPage){
    return(
        <div className='columns is-desktop custom-m-top custom-auto-margin custom-max-width-responsive'>
            {/* Section One */}
            <div className='column custom-main-page-txt-width custom-flex-0-0-65'>
                <h2 className='custom-without-margin custom-font-f custom-base-font-color custo-font-size-36 has-text-weight-normal'>{mi.section_one_title.text}</h2>
                <span className='custo-text-color is-size-4 custom-font-f'>{mi.section_one_subtitle.text}</span>
                <div className='content custom-content-margin'>
                    {mi.section_one_txt.map((obj: any) => (
                        <div className='content is-flex custom-position-relative'>
                            <img className='custom-blue-arrow' src={yellow_arrow} />
                            <span className='custom-base-font-color custom-font-f custom-txt-with-arrow'>{obj.text}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='column custom-main-page-images'>
                <figure className='image'>
                    <img src={section_one_img} alt="" />
                </figure>
            </div>
        </div>
    )
}

function SectionTwo(mi: MainPage){
    return(
        <div className='columns is-desktop custom-m-top custom-auto-margin custom-max-width-responsive'>
            {/* Section Two */}
            <div className='column custom-main-page-txt-width custom-flex-0-0-65'>
                <span className='custo-text-color custo-font-size-36 custom-font-f'>{mi.section_two_subtitle.text}</span>
                <div className='content custom-content-margin'>
                    {mi.section_two_txt.map((obj: any) => (
                        <div className='content is-flex custom-position-relative'>
                            <img className='custom-blue-arrow' src={yellow_arrow} />
                            <span className='custom-base-font-color custom-font-f custom-txt-with-arrow'>{obj.text}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='column custom-main-page-images'>
                <figure className='image custom-max-width-img'>
                    <img src={section_two_img} alt="" />
                </figure>
            </div>
        </div>
    )
}

function SectionThree(mi: MainPage){
    return(
        <div className='columns is-desktop custom-m-top custom-auto-margin custom-max-width-responsive'>
            {/* Section Three */}
            <div className='column custom-main-page-txt-width custom-flex-0-0-65'>
                <span className='custo-text-color custo-font-size-36 custom-font-f'>{mi.section_three_subtitle.text}</span>
                <div className='content custom-content-margin'>
                    {mi.section_three_txt.map((obj: any) => (
                        <div className='content is-flex custom-position-relative'>
                            <img className='custom-blue-arrow' src={yellow_arrow} />
                            <span className='custom-base-font-color custom-font-f custom-txt-with-arrow'>{obj.text}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='column custom-main-page-images'>
                <figure className='image custom-max-width-img'>
                    <img src={section_three_img} alt="" />
                </figure>
            </div>
        </div>
    )
}

function SectionFour(mi: MainPage){
    return(
        <div className='columns is-desktop custom-m-top custom-auto-margin custom-max-width-responsive is-flex-wrap-wrap'>
            {/* Section Four */}
            <div className='column custom-flex-0-0-100'>
                <h4 className='custom-without-margin custom-font-f custom-base-font-color custo-font-size-36 has-text-weight-normal'>{mi.section_four_title.text}</h4>
                <span className='custom-blue-font-color custo-font-size-36 custom-font-f'>{mi.section_four_subtitle.text}</span>
            </div>
            <div className='column custom-max-width-506'>
                <div className='content custom-content-margin'>
                    {mi.section_four_txt.map((obj: any) => (
                        <div className='content is-flex custom-position-relative'>
                            <img className='custom-blue-arrow' src={arrow} />
                            <span className='custom-base-font-color custom-font-f custom-txt-with-arrow'>{obj.text}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='column'>
                <figure className='image custom-max-width-img'>
                    <img src={section_four_img} alt="" />
                </figure>
            </div>
        </div>
    )
}


function DefaultMainP(mi: MainPage){
    if(mi.mainp_data.length > 0){
        return(
            <div>
                <div className="columns custom-without-margin">
                    <div className="column custom-without-padding">
                        <div className='is-desktop is-flex custom-bcg-img-main-page' style={{ backgroundImage: `url(${unios_slider})`, marginTop: '75px', width: '100%'}}></div>
                        <div className="column is-flex custom-auto-margin custom-max-width-responsive is-flex-direction-column is-align-items-center custom-margin-bottom">
                            <h1 className="title has-text-centered custom-font-f custom-base-font-color custom-main-page-title has-text-weight-normal">{mi.main_p_title.text}</h1>
                            <h2 className="subtitle has-text-centered custom-font-f custom-base-font-color custom-main-page-subtitle">{mi.main_p_subtitle.text}</h2>
                        </div>
                    </div>
                </div>
                <SectionOne {...mi}/>
                <SectionTwo {...mi}/>
                <SectionThree {...mi}/>
                <SectionFour {...mi}/>
            </div>
        )
    }else{
        return <div></div>
    }
}


function MainPageComponent() {
    const mainp_items = useSelector((state: RootState) => state.mainp);
    const lang = useSelector((state: RootState) => state.header.current_lang);
    const dispatch = useDispatch();

    React.useEffect(
        () => getMainPageData(dispatch, lang), [lang.lang]
    )
    return (
            <DefaultMainP {...mainp_items}/>
    );
  }
  
  export default MainPageComponent;
