import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import 'bulma/css/bulma.min.css';
import API from '../app/api';
import { setLangs } from '../features/langSlice';
import { handleToggle, setCurrentLang, setData, setCurrentPage, setPageWidth, showMobileMenu } from '../features/headerSlice';
import  './styles.scss';
import { Link } from 'react-router-dom';
import { HeaderM } from '../models/headerModel';
import unios_logo from '../static/unios_logo.svg'


function DefaultHeader({disp, hi}: any){
    const lang_items = useSelector((state: RootState) => state.langs);
    if(hi.header_data.length > 0){
        setCurPage(disp, 0);
        return(
            <nav className="navbar is-fixed-top custom-nav-hieght-75" role="navigation" aria-label="main navigation">
                <div className="navbar-brand custom-auto-margin custom-nav is-relative is-flex is-align-items-center">
                    <img className="navbar-item logo-m custom-header-logo" src={unios_logo} />
                    <Link className={"navbar-item custom-header-text-color " + (hi.current_page === 1 ? 'custom-header-section-active' : '')} to="/" 
                        onClick={() => setCurPage(disp, 1)}>{hi.header_main.text}</Link>
                    
                    <Link className={"navbar-item custom-header-text-color " + (hi.current_page === 2 ? 'custom-header-section-active' : '')} to="vendors/" 
                        onClick={() => setCurPage(disp, 2)}>{hi.header_vendors.text}</Link>
                    
                    <Link className={"navbar-item custom-header-text-color " + (hi.current_page === 3 ? 'custom-header-section-active' : '')} to="owners/" 
                        onClick={() => setCurPage(disp, 3)}>{hi.header_owners.text}</Link>
                    
                    <Link className={"navbar-item custom-header-text-color " + (hi.current_page === 4 ? 'custom-header-section-active' : '')} to="service/" 
                        onClick={() => setCurPage(disp, 4)}>{hi.header_services.text}</Link>
                    
                    <Link className={"navbar-item custom-header-text-color " + (hi.current_page === 5 ? 'custom-header-section-active' : '')} to="for-developers/"
                        onClick={() => setCurPage(disp, 5)}>{hi.header_devs.text}</Link>
                    
                    <Link className={"navbar-item custom-header-text-color " + (hi.current_page === 6 ? 'custom-header-section-active' : '')} to="news/" 
                        onClick={() => setCurPage(disp, 6)}>{hi.header_news.text}</Link>

                    <a className="navbar-item custom-margin-left-auto" href="https://go.unios.io/ng/login">Вход</a>
                </div>
            </nav>
        )
    }else{
        return(<div></div>) 
    }
}

function setCurPage(d: any, page_n: number){
    if(page_n != 0){
        d(setCurrentPage(page_n));
    }else{
        d(setCurrentPage(pageNumberByUrl()))
    }
}

function pageNumberByUrl(){
    let c_url = window.location.href;
    if(c_url.includes('vendors')){
        return 2;
    }else if(c_url.includes('owners')){
        return 3;
    }else if(c_url.includes('service')){
        return 4;
    }else if(c_url.includes('for-developers')){
        return 5;
    }else if(c_url.includes('news')){
        return 6;
    }else{
        return 1;
    }
}

function BurgerHeader({disp, hi}: any){
    if(hi.header_data.length > 0){
        return(
            <nav className={"navbar-burger custom-nav custom-auto-margin is-align-items-center " + (hi.show_mobile_menu != undefined ? (hi.show_mobile_menu ? 'is-active' : '') : '')}
                        role="navigation" aria-label="main navigation">
                    <img className="navbar-item logo-m" src={unios_logo} />
                    <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" onClick={() => visionOfMobileMenu(disp)}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
            </nav>
        )
    }else{
        return(<div></div>) 
    }
}

function visionOfMobileMenu(d: any){
    d(showMobileMenu());
}

function MobileMenu(hi: HeaderM){
    const dispatch = useDispatch();
    const lang_items = useSelector((state: RootState) => state.langs);
    if(hi.header_data.length > 0){
        return(
            <div className={'custom-mobile-menu ' + (hi.show_mobile_menu != undefined ? (hi.show_mobile_menu ? 'slide-in' : 'slide-out') : '')}>
               <nav className="custom-nav is-active custom-auto-margin is-align-items-center" 
                        role="navigation" aria-label="main navigation" style={{flexWrap: 'wrap'}}>
                    {/* <div className="navbar-brand is-flex is-justify-content-space-between" style={{flex: '100%'}}>
                        <img className="navbar-item logo-m" src={unios_logo} />
                        <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" onClick={() => visionOfMobileMenu(dispatch)}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div> */}

                    <div className="navbar-brand is-flex is-flex-direction-column">
                        <a className="navbar-item is-size-5" href="https://go.unios.io/ng/login">Вход</a>
                        <Link className="navbar-item custom-header-text-color is-size-5" to="/" 
                            onClick={() => visionOfMobileMenu(dispatch)}>{hi.header_main.text}</Link>

                        <Link className="navbar-item custom-header-text-color is-size-5" to="vendors/" 
                            onClick={() => visionOfMobileMenu(dispatch)}>{hi.header_vendors.text}</Link>

                        <Link className="navbar-item custom-header-text-color is-size-5" to="owners/" 
                            onClick={() => visionOfMobileMenu(dispatch)}>{hi.header_owners.text}</Link>

                        <Link className="navbar-item custom-header-text-color is-size-5" to="service/" 
                            onClick={() => visionOfMobileMenu(dispatch)}>{hi.header_services.text}</Link>

                        <Link className="navbar-item custom-header-text-color is-size-5" to="for-developers/"
                            onClick={() => visionOfMobileMenu(dispatch)}>{hi.header_devs.text}</Link>

                        <Link className="navbar-item custom-header-text-color is-size-5" to="news/" 
                            onClick={() => visionOfMobileMenu(dispatch)}>{hi.header_news.text}</Link>
                    </div>
                </nav>
            </div>
        )
    }else{
        return <div></div>
    }
}

function reloadData(d: any, lang: any){
    d(setCurrentLang(lang));
    getHeaderItem(d, lang);
}

function hToggle(d: any){
    d(handleToggle());
}


function getLang(d: any, hi: any){
    API.get('api/language/').then(
        res => {
            d(setLangs(res.data));
            d(setCurrentLang(res.data[0]));
            getHeaderItem(d, res.data[0])
        }
    )
}

function getHeaderItem(d: any, init_d: any){
    API.get(`api/data/?lang=${init_d.id}&page=header`).then(
        res => {
            d(setData(res.data));
        }
    )
}

function useWindowSize(d: any) {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.screen.width]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    d(setPageWidth(size[0]));
  }

function Header() {
  const header_items = useSelector((state: RootState) => state.header);
  const dispatch = useDispatch();
  
  useWindowSize(dispatch)
  React.useEffect(
    () => getLang(dispatch, header_items), []);
    
  if(header_items.page_width >= 1024){
      return (
          <DefaultHeader disp={dispatch} hi={header_items}/>
      );
  }else{
        return (
            <div>
                <MobileMenu {...header_items}/>
                <BurgerHeader disp={dispatch} hi={header_items}/>
            </div>
        );
  }
}

export default Header;