import { createSlice } from '@reduxjs/toolkit';
import { MainPage } from '../models/mainPModel';

const initialState: MainPage = {
    mainp_data: [],
    unios_slider: undefined,
    main_p_title: undefined,
    main_p_subtitle: undefined,
    section_one_title: undefined,
    section_one_subtitle: undefined,
    section_one_img: undefined,
    section_one_txt: [],
    section_two_title: undefined,
    section_two_subtitle: undefined,
    section_two_img: undefined,
    section_two_txt: [],
    section_three_title: undefined,
    section_three_subtitle: undefined,
    section_three_img: undefined,
    section_three_txt: [],
    section_four_title: undefined,
    section_four_subtitle: undefined,
    section_four_img: undefined,
    section_four_txt: []
  }

export const mainPSlice = createSlice({
  name: 'mainP',
  initialState,
  reducers: {
    setData: (state, action ) => {
        state.mainp_data = action.payload;
        action.payload.map((obj: any) => {
            if(obj.tag === 'unios_slider'){
                state.unios_slider = obj
            }

            if(obj.tag === 'main_p_title'){
                state.main_p_title = obj
            }

            if(obj.tag === 'main_p_subtitle'){
                state.main_p_subtitle = obj
            }

            if(obj.tag === 'section_one_title'){
                state.section_one_title = obj
            }

            if(obj.tag === 'section_one_subtitle'){
                state.section_one_subtitle = obj
            }

            if(obj.tag === 'section_one_img'){
                state.section_one_img = obj
            }

            if(obj.tag === 'section_one_data'){
                state.section_one_txt.push(obj);
            }

            if(obj.tag === 'section_two_title'){
                state.section_two_title = obj
            }

            if(obj.tag === 'section_two_subtitle'){
                state.section_two_subtitle = obj
            }

            if(obj.tag === 'section_two_img'){
                state.section_two_img = obj
            }

            if(obj.tag === 'section_two_data'){
                state.section_two_txt.push(obj);
            }

            if(obj.tag === 'section_three_title'){
                state.section_three_title = obj
            }

            if(obj.tag === 'section_three_subtitle'){
                state.section_three_subtitle = obj
            }

            if(obj.tag === 'section_three_img'){
                state.section_three_img = obj
            }

            if(obj.tag === 'section_three_data'){
                state.section_three_txt.push(obj);
            }

            if(obj.tag === 'section_four_title'){
                state.section_four_title = obj
            }

            if(obj.tag === 'section_four_subtitle'){
                state.section_four_subtitle = obj
            }

            if(obj.tag === 'section_four_img'){
                state.section_four_img = obj
            }

            if(obj.tag === 'section_four_data'){
                state.section_four_txt.push(obj);
            }
        })
    },

    clearState: (state) => {
        return initialState;
    }
  }
});

export const { setData, clearState } = mainPSlice.actions;


export default mainPSlice.reducer;