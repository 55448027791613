import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RootState } from '../app/store';
import 'bulma/css/bulma.min.css';
import API, { selectURL } from '../app/api';
import  './styles.scss';
import { setData, reset } from '../features/oneNewsSlice';
import { Helmet } from "react-helmet";

function getNews(d: any, params: any, lang: any){
    d(reset());
    API.get(`api/one-news/?news_id=${params.id}&lang=${lang.id}`).then(
        res => {
            d(setData(res.data));
        }
    )
}

function DefaultOneNews(){
    const one_news = useSelector((state: RootState) => state.one_news);
    const navigate = useNavigate();

    React.useEffect(
        () => window.scrollTo(0, 0), []
    )

    if(one_news.title != ''){
        return(
            <div className='columns custom-auto-margin custom-max-width-responsive custom-margin-top-70'>
                <div className='column'>
                    <div className='content is-flex is-flex-direction-column custom-margin-bottom-60'>
                        <h2 className='title custom-page-title2 custom-base-font-color custom-font-f'>
                            {one_news.title}
                        </h2>
                        <span className='custom-font-f custom-blue-font-color'>
                            {one_news.date}
                        </span>
                    </div>
                    <div className='columns'>
                        <div className='column custom-base-font-color custom-font-f custom-news-fulltext'>
                                {one_news.text}
                        </div>
                        <div className='column'>
                            <img src={`${selectURL()}/api/get-file/?filename=${one_news.filename}`} alt="" />
                        </div>
                    </div>
                    <div className='columns'>
                        <div className='column'>
                            <div className="buttons">
                                    <button className="button is-link custom-back-btn responsive-btn" onClick={() => navigate(-1)}>{one_news.btn}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return <div></div>
    }
}

function OneNews() {
    const dispatch = useDispatch();
    const params = useParams();
    const lang = useSelector((state: RootState) => state.header.current_lang);
    React.useEffect(
        () => getNews(dispatch, params, lang), [lang.lang]);

    return(
        <>
            <Helmet>
                <title></title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>
            <DefaultOneNews />
        </>
    )
  }
  
  export default OneNews;
