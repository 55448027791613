import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import 'bulma/css/bulma.min.css';
import API from '../app/api';
import  './styles.scss';
import { setData, setCardsData, reset } from '../features/serviceSlice';
import { ServiceM } from '../models/serviceModel';
import service_title from '../static/service_title.png';
import service_bcg from '../static/service_bcg.png';
import { Helmet } from "react-helmet";

function DefaultService(si: ServiceM){
    if(si.service_data.length > 0){
        return(
            <div>
                <div className='columns is-desktop is-flex custom-bcg-img custom-margin-bottom-60 
                        custom-columns-without-margin' style={{ backgroundImage: `url(${service_bcg})`, marginTop: '75px'}}>
                    <div className='column custom-auto-margin custom-max-width-responsive is-grid is-align-items-center is-justify-content-flex-start'>
                        <section className='custom-responsive-margin custom-auto-margin custom-max-width-responsive'>
                            <h2 className="title custom-page-title2 custom-base-font-color custom-font-f">{si.section_one_title.text}</h2>
                            <h3 className="subtitle custom-base-font-color custom-font-f custom-page-title">
                              {si.section_one_subtitle.text}
                            </h3>
                        </section>
                    </div>
                </div>
                <SectionTwo {...si}/>
                <SectionThree {...si}/>
            </div>
        )
    }else{
        return <div></div>
    }
}

function SectionTwo(si: ServiceM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive abc'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color page-content-h2 has-text-weight-normal">{si.section_two_title.text}</h2>
                    </div>
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width is-multiline custom-auto-margin custom-max-width-responsive'>
                {si.section_two_raw_content.map((obj: any) => (
                    <div className='column is-one-third' >
                        <h4 className='custom-title custom-font-f custom-base-font-color page-content-h4 custom-without-margin'
                            style={{ backgroundImage: `url(${service_title})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 'left' }}>
                            {obj.title}
                        </h4>
                        <span className='custom-font-f custom-base-font-color has-text-weight-normal custom-txt-with-arrow'>
                            {obj.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

function SectionThree(si: ServiceM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color page-content-h2 has-text-weight-normal">{si.section_three_title.text}</h2>
                    </div>
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width is-multiline custom-auto-margin custom-max-width-responsive'>
                {si.section_three_raw_content.map((obj: any) => (
                    <div className='column is-one-third' >
                        <h4 className='custom-title custom-font-f custom-base-font-color page-content-h4 custom-without-margin'
                            style={{ backgroundImage: `url(${service_title})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 'left' }}>
                            {obj.title}
                        </h4>
                        <span className='custom-font-f custom-base-font-color has-text-weight-normal custom-txt-with-arrow'>
                            {obj.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

function getServiceData(d: any, lang: any){
    d(reset());
    API.get(
        `api/data/?lang=${lang.id == 0 ? 1 : lang.id}&page=service`
    ).then((res: any) => {
        d(setData(res.data));
    })
    
    API.get(
        `api/cardata/?lang=${lang.id == 0 ? 1 : lang.id}&page=service`
    ).then((res: any) => {
        d(setCardsData(res.data));
    })
}

function ServiceComponent() {
    const service_items = useSelector((state: RootState) => state.service);
    const lang = useSelector((state: RootState) => state.header.current_lang);
    const dispatch = useDispatch();

    React.useEffect(
        () => getServiceData(dispatch, lang), [lang.lang]);
  
    return(
        <>
            <Helmet>
                <title>Unios - автоматизация сервиса</title>
                <meta name="description" content="Интеграция с имеющимся ПО, сбор и анализ данных, контроль внутреннего сервиса. Регистрация." />
                <meta name="keywords" content="" />
            </Helmet>
            <DefaultService {...service_items}/>
        </>
    )
  }
  
  export default ServiceComponent;
