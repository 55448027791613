import { createSlice } from '@reduxjs/toolkit';
import { Footer } from '../models/footerModel';

const initialState: Footer = {
    footer_data: [],
    footer_man: undefined,
    footer_unios_logo: undefined,
    footer_phone: undefined,
    footer_email: undefined,
    footer_enter: undefined,
    footer_reg: undefined,
    footer_ftx: undefined,
    footer_partners: [],
    footer_main: undefined,
    footer_vendor: undefined,
    footer_owner: undefined,
    footer_service: undefined,
    footer_about: undefined,
    section_five_title: undefined,
    section_five_subtitle: undefined,
    section_five_btn: undefined,
  }

function selectField(state: any, obj: any){
    return state[obj.tag] = obj
}

export const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    setData: (state, action ) => {
        if(state.footer_data.length > 0){
            return initialState
        }
        state.footer_data = action.payload
        action.payload.map((obj: any) => {
            if(obj.tag === 'footer_partners'){
                state.footer_partners.push(obj);
            }else{
                selectField(state, obj);
            }
        })
    }
  }
});

export const { setData } = footerSlice.actions;


export default footerSlice.reducer;