import { createSlice } from '@reduxjs/toolkit';
import { LangM } from '../models/langModel';

const initialState: LangM = {
    langs: []
  }

export const langSlice = createSlice({
  name: 'langs',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLangs: (state, action ) => {
        state.langs = action.payload
    }
  }
});

export const { setLangs } = langSlice.actions;


export default langSlice.reducer;