import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import 'bulma/css/bulma.min.css';
import API from '../app/api';
import  './styles.scss';
import { OwnersM } from '../models/ownersModel';
import { setData, setCardsData, reset } from '../features/ownersSlice';
import owners_title from '../static/owners_title.png';
import owners_bcg from '../static/owners_bcg.png';
import owners_img_one from '../static/owners_img_one.png';
import owners_img_two from '../static/owners_img_two.png';
import { Helmet } from "react-helmet";

function DefaultOwners(oi: OwnersM){
    if(oi.owners_data.length > 0){
        return(
            <div>
                <div className='columns is-desktop is-flex custom-bcg-img custom-margin-bottom-60 
                        custom-columns-without-margin' style={{ backgroundImage: `url(${owners_bcg})`,  marginTop: '75px' }}>
                    <div className='column custom-auto-margin custom-max-width-responsive is-grid is-align-items-center is-justify-content-flex-start'>
                        <div className='custom-responsive-margin custom-auto-margin custom-max-width-responsive'>
                            <h2 className="title custom-page-title2 custom-base-font-color custom-font-f">{oi.section_one_title.text}</h2>
                            <h3 className="subtitle custom-base-font-color custom-font-f custom-page-title">
                              {oi.section_one_subtitle.text}
                            </h3>
                        </div>
                    </div>
                </div>
                <SectionTwo {...oi}/>
                <ImgOne {...oi}/>
                <SectionThree {...oi}/>
                <ImgTwo {...oi}/>
                <SectionFour {...oi}/>
            </div>
        )
    }else{
        return <div></div>
    }
}

function SectionTwo(oi: OwnersM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive abc'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color page-content-h2 has-text-weight-normal">{oi.section_two_title.text}</h2>
                    </div>
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width is-multiline custom-auto-margin custom-max-width-responsive'>
                {oi.section_two_raw_content.map((obj: any) => (
                    <div className='column is-one-third'>
                        <h4 className='custom-title custom-font-f custom-base-font-color page-content-h4 custom-without-margin' 
                                style={{ backgroundImage: `url(${owners_title})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 'left' }}>
                            {obj.title}
                        </h4>
                        <span className='custom-font-f custom-base-font-color has-text-weight-normal custom-txt-with-arrow'>
                            {obj.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

function SectionThree(oi: OwnersM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color page-content-h2 has-text-weight-normal">{oi.section_three_title.text}</h2>
                    </div>
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width is-multiline custom-auto-margin custom-max-width-responsive'>
                {oi.section_three_raw_content.map((obj: any) => (
                    <div className='column is-one-third' >
                        <h4 className='custom-title custom-font-f custom-base-font-color page-content-h4 custom-without-margin' 
                                style={{ backgroundImage: `url(${owners_title})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 'left' }}>
                            {obj.title}
                        </h4>
                        <span className='custom-font-f custom-base-font-color has-text-weight-normal custom-txt-with-arrow'>
                            {obj.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

function SectionFour(oi: OwnersM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color page-content-h2 has-text-weight-normal">{oi.section_four_title.text}</h2>
                    </div>
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width is-multiline custom-auto-margin custom-max-width-responsive'>
                {oi.section_four_raw_content.map((obj: any) => (
                    <div className='column is-one-third' >
                        <h4 className='custom-title custom-font-f custom-base-font-color page-content-h4 custom-without-margin' 
                                style={{ backgroundImage: `url(${owners_title})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 'left' }}>
                            {obj.title}
                        </h4>
                        <span className='custom-font-f custom-base-font-color has-text-weight-normal custom-txt-with-arrow'>
                            {obj.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

function ImgOne(oi: OwnersM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-margin-bottom custom-auto-margin custom-max-width-responsive'>
                <div className='column is-flex is-justify-content-center'>
                    <figure className="image custom-is-820x560">
                        <img src={owners_img_one} />
                    </figure>
                </div>
            </div>
        </div>
    )
}

function ImgTwo(oi: OwnersM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-margin-bottom custom-auto-margin custom-max-width-responsive'>
                <div className='column is-flex is-justify-content-center'>
                    <figure className="image custom-is-820x560">
                        <img src={owners_img_two} />
                    </figure>
                </div>
            </div>
        </div>
    )
}

function getOwnersData(d: any, lang: any){
    d(reset());
    API.get(
        `api/data/?lang=${lang.id == 0 ? 1 : lang.id}&page=owner`
    ).then((res: any) => {
        d(setData(res.data));
    })
    
    API.get(
        `api/cardata/?lang=${lang.id == 0 ? 1 : lang.id}&page=owner`
    ).then((res: any) => {
        d(setCardsData(res.data));
    })
}

function OwnersComponent() {
    const owner_items = useSelector((state: RootState) => state.owners);
    const lang = useSelector((state: RootState) => state.header.current_lang);
    const dispatch = useDispatch();

    React.useEffect(
        () => getOwnersData(dispatch, lang), [lang.lang]);
  
    return(
        <>
            <Helmet>
                <title>Unios - инструмент автодиагностики оборудования</title>
                <meta name="description" content="Умная диспетчеризация и мониторинг, автоматизация ТОиР. Подключение корпоративных клиентов." />
                <meta name="keywords" content="" />
            </Helmet>
            <DefaultOwners {...owner_items}/>
        </>
    )
  }
  
  export default OwnersComponent;
