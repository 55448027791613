import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import 'bulma/css/bulma.min.css';
import API from '../app/api';
import  './styles.scss';
import { DevsM } from '../models/devsModel';
import { setData, changeInstruction, reset } from '../features/devsSlice';
import devs_bcg from '../static/devs_bcg.png';
import { Helmet } from "react-helmet";


function DefaultOwners(di: DevsM){
    if(di.devs_data.length > 0){
        return(
            <div>
                <div className='columns is-desktop is-flex custom-bcg-img custom-margin-bottom-60 
                        custom-columns-without-margin' style={{ backgroundImage: `url(${devs_bcg})`, marginTop: '75px'}}>
                <div className='column custom-auto-margin custom-max-width-responsive is-grid is-align-items-center is-justify-content-flex-start'>
                        <section className='custom-responsive-margin custom-auto-margin custom-max-width-responsive'>
                            <h2 className="title custom-page-title2 custom-base-font-color custom-font-f">{di.section_one_title.text}</h2>
                            <h3 className="subtitle custom-base-font-color custom-font-f custom-page-title">
                              {di.section_one_subtitle.text}
                            </h3>
                        </section>
                    </div>
                </div>
                <SectionTwo {...di} />
                <SectionThree {...di} />
                <SectionFour {...di} />
                <SectionFive {...di} />
            </div>
        )
    }else{
        return <div></div>
    }
}

function SectionTwo(di: DevsM){
    return(<div className='content custom-auto-margin custom-max-width-responsive custom-margin-top-91 custom-margin-bottom-40'>
        <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>{di.section_two_title.text}</span>
        <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container'>{di.section_two_content.text}</div>
    </div>)
}

function SectionThree(di: DevsM){
    return(<div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
        <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>{di.section_three_title.text}</span>
        <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>{di.section_three_content.text}</div>
        <a href="https://go.unios.io/ng/signup/personal" 
            className='is-size-6 custom-blue-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                {di.first_href.text}
        </a>
        <a href="https://go.unios.io/ng/vendor/create_device_model" 
            className='is-size-6 custom-blue-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                {di.second_href.text}
        </a>
    </div>)
}

function SectionFour(di: DevsM){
    return(<div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
        <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>{di.section_four_title.text}</span>
        <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>{di.section_four_content.text}</div>
        <a href="https://go.unios.io/ng/signup/personal" 
            className='is-size-6 custom-blue-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
            {di.first_href.text}
        </a>
        <a href="https://go.unios.io/ng/vendor/create_device_model"
            className='is-size-6 custom-blue-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
            {di.second_href.text}
        </a>
    </div>)
}

function SectionFive(di: DevsM){
    let lang = useSelector((state: RootState) => state.header.current_lang);
    const dispatch = useDispatch();
    return(
        <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
            <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>{di.section_five_title.text}</span>
            <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>{di.section_five_content.text}</div>
            <div className='is-flex is-justify-content-space-around custom-devs-container custom-margin-t-b'>
                <div className={'custom-blue-font-color custom-font-f has-text-weight-medium custom-font-size-30' + (di.rest_api ? ' custom-underline' : ' custom-opacity')}
                    onClick={() => changeInst(dispatch)}>
                    REST API
                </div>
                <div className={'custom-blue-font-color custom-font-f has-text-weight-medium custom-font-size-30' + (!di.rest_api ? ' custom-underline' : ' custom-opacity')}
                    onClick={() => changeInst(dispatch)}>
                    SOCKET API
                </div>
            </div>
            <SectionSix lang={lang} di={di} />
            <SectionSeven lang={lang} di={di}/>
            <SectionEight lang={lang} di={di}/>
        </div>
    )
}

function SectionSix(obj: any){
    if(obj.di.rest_api === true){
        if(obj.lang.lang === 'ru'){
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>Шаг 4. Разновидность передаваемых данных </span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        -Отправка событий <br/>
                        Для отправки событий в теле запроса (body) указывается наименование события.<br/>
                        Пример: 'name_event'<br/>
                        -Отправка телеметрии<br/>
                        Для отправки телеметрии в теле запроса (body) указывается наименование и значение телеметрии<br/>
                        Пример: 'name_telemetry: 345;'
                    </div>
                </div>
            )
        }else{
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>Step 4. Types of data</span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        -Events&nbsp;<br/>
                        The user should specify the name of an event in the request body to send events<br/>i.e.: 'name_event'<br/>
                        -Telemetry<br/>The user should specify the name of an event in the request body to send telemetry<br/>i.e.: 'name_telemetry: 345;'
                    </div>
                </div>
            )
        }
    }else{
        if(obj.lang.lang === 'ru'){
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>Шаг 4. Формат передаваемых данных </span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        Для настройки передачи данных в теле необходимо указать идентификатор производителя и серийный номер устройства. <br />
                            - Пример формирования данных для передачи события: <br />
                            data = 'Uuid: aca24517-9168-4891-af14-dsdsds230daa2; serial: CPU-220-01; tmp;' <br />
                            где tmp - наименование события <br />
                            - Пример формирования данных для передачи телеметрии: <br />
                            data = 'Uuid: aca24517-9168-4891-af14-dsdsds230daa2; serial: CPU-220-01; tmp: 123;' <br />
                            где tmp - наименование телеметрии
                    </div>
                </div>
            )
        }else{
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>Step 4. Types of data  </span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        You should specify a vendor UUID and device serial number in the request body to send data to the platform <br/>
                        - to send events: <br/>
                        data = 'Uuid: aca24517-9168-4891-af14-dsdsds230daa2; serial: CPU-220-01; tmp;' <br/>
                        where tmp is a name of an event <br/>
                        - to send telemetry: <br/>
                        data = 'Uuid: aca24517-9168-4891-af14-dsdsds230daa2; serial: CPU-220-01; tmp: 123;' <br/>
                        where tmp is a name of telemetry value
                    </div>
                </div>
            )
        }
    }
}

function SectionSeven(obj: any){
    if(obj.di.rest_api){
        if(obj.lang.lang === 'ru'){
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>Шаг 5. Пример отправки данных на платформу UNIOS</span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>Для отправки события:</div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        curl <br/><span className='is-size-6 custom-blue-font-color custom-font-f'>-H </span>"Content-Type: text" \<br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H </span>"serial: CPU-22-22" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H </span>"Uuid: aca24517-9168-4891-af14-dsdsds230daa2" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-X </span>POST \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-d </span>'tmp' \ <br/>
                        https://go.unios.io/api/events <br/>
                    </div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>Для отправки телеметрии:</div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        curl <br/><span className='is-size-6 custom-blue-font-color custom-font-f'>-H</span> "Content-Type: text" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H</span> "serial: CPU-22-22" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H</span> "Uuid: aca24517-9168-4891-af14-dsdsds230daa2" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-X</span> POST \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-d</span> 'tmp: 345;' \ <br/>
                        https://go.unios.io/api/events <br/>
                    </div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>Пример с зашифрованными данными: </div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        curl <br/><span>-H</span> "Content-Type: text" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H</span> "serial: CPU-22-22" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H</span> "Uuid: aca24517-9168-4891-af14-dsdsds230daa2" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-X</span> POST \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-d</span> 'LO\x1f\xc9\x81&gt;gR\xa3Jw\x87\x94&gt;\x18k\' \ <br/>
                        https://go.unios.io/api/events <br/>
                    </div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        Если ваше устройство не поддерживает протокол HTTPS, необходимо заменить адрес отправки на: http://185.53.22.30:8171
                    </div>
                </div>
            )
        }else{
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>Step 5. Examples </span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>To send an event:</div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        curl <br/><span>-H </span>"Content-Type: text" \<br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H </span>"serial: CPU-22-22" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H </span>"Uuid: aca24517-9168-4891-af14-dsdsds230daa2" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-X </span>POST \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-d </span>'tmp' \ <br/>
                        https://go.unios.io/api/events <br/>
                    </div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>To send telemetry:</div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        curl <br/><span>-H</span> "Content-Type: text" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H</span> "serial: CPU-22-22" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H</span> "Uuid: aca24517-9168-4891-af14-dsdsds230daa2" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-X</span> POST \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-d</span> 'tmp: 345;' \ <br/>
                        https://go.unios.io/api/events <br/>
                    </div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>Encryption example: </div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        curl <br/><span>-H</span> "Content-Type: text" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H</span> "serial: CPU-22-22" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-H</span> "Uuid: aca24517-9168-4891-af14-dsdsds230daa2" \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-X</span> POST \ <br/>
                        <span className='is-size-6 custom-blue-font-color custom-font-f'>-d</span> 'LO\x1f\xc9\x81&gt;gR\xa3Jw\x87\x94&gt;\x18k\' \ <br/>
                        https://go.unios.io/api/events <br/>
                    </div>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        To establish the HTTP connection you should change the address to http://185.53.22.30:8171
                    </div>
                </div>
            )
        }
    }else{
        if(obj.lang.lang === 'ru'){
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>Шаг 5. Куда передавать данные </span>
                    <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        Данные необходимо отправлять по следующему адресу: 
                    </span>
                    <div className='is-flex'>
                        <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                            IP =</span> <span className='custom-blue-font-color'>185.53.22.30</span><br/>
                    </div>
                    <div className='is-flex'>
                        <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                            Порт =</span> <span className='custom-blue-font-color'>4640</span>
                    </div>
                </div>
            )
        }else{
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>Step 5. Sending data </span>
                    <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        You have to specify the following address:  
                    </span>
                    <div className='is-flex'>
                        <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                            IP =</span> <span className='custom-blue-font-color'>185.53.22.30</span><br/>
                    </div>
                    <div className='is-flex'>
                        <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                            Порт =</span> <span className='custom-blue-font-color'>4640</span>
                    </div>
                </div>
            )
        }
    }
}

function SectionEight(obj: any){
    if(obj.di.rest_api){
        if(obj.lang.lang === 'ru'){
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>
                        Шаг 6. Пример отправки данных на платформу UNIOS на python 
                    </span>
                        <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                            url = 'http://185.53.22.30:8171' <span className='custom-blue-font-color'># Дополнительный адрес. Использовать его, если ваше устройство не поддерживает HTTPS протокол</span> <br/>
                            url = 'https://go.unios.io/api/events' <span className='custom-blue-font-color'># Приоритетный адрес </span> <br/> <br/>
    
                            headers = {'{'}  <br/>
                            &nbsp; &nbsp; &nbsp;"Content-Type": "text", <br/>
                            &nbsp; &nbsp; &nbsp;"serial": "CPU-220-01", <span className='custom-blue-font-color'># Серийный номер вашего устройства</span> <br/>
                            &nbsp; &nbsp; &nbsp;"Uuid": "aca24517-9168-4891-af14-dsdsds230daa2" <span className='custom-blue-font-color'># Uuid идентификатор Производителя</span>  <br/>
                            {'}'}<br/> <br/>
                            requests.post(url, headers=headers, data=b'tmp: 20; pres: 55;') <span className='custom-blue-font-color'># Незашифрованные данные</span> <br/>
    
                            requests.post(url, headers=headers, data=b'LO\x1f\xc9\x81&gt;gR\xa3Jw\x87\') <div><span className='custom-blue-font-color'># Зашифрованные данные </span></div>
                        </div>
                </div>
            )
        }else{
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>
                        Step 6. Requests in python 
                    </span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                            url = 'http://185.53.22.30:8171' <span className='custom-blue-font-color'># additional address. If your device cannot send data via HTTPS, use this address </span> <br/>
                            url = 'https://go.unios.io/api/events' <span className='custom-blue-font-color'># main address  </span> <br/> <br/>
    
                                headers = {'{'}  <br/>
                                &nbsp; &nbsp; &nbsp;"Content-Type": "text", <br/>
                                &nbsp; &nbsp; &nbsp;"serial": "CPU-220-01", <span className='custom-blue-font-color'># serial number of your device </span> <br/>
                                &nbsp; &nbsp; &nbsp;"Uuid": "aca24517-9168-4891-af14-dsdsds230daa2" <span className='custom-blue-font-color'># Uuid of a Vendor  </span>  <br/>
                                {'}'}<br/> <br/>
                                requests.post(url, headers=headers, data=b'tmp: 20; pres: 55;') <span className='custom-blue-font-color'> # unencrypted data</span> <br/>
    
                                requests.post(url, headers=headers, data=b'LO\x1f\xc9\x81&gt;gR\xa3Jw\x87\') <div><span className='custom-blue-font-color'># encrypted data </span></div>
                            </div>
                </div>
            )
        }
    }else{
        if(obj.lang.lang === 'ru'){
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'>
                        Шаг 6. Пример отправки данных на платформу UNIOS на python 
                    </span>
                    <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        Пример с незашифрованными данными:
                    </span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        sock = socket.socket() <br/>
                        sock.connect(('185.53.22.30', 4640)) <br/>
                        sock.send('Uuid: aca24517-9168-4891-af14-dsdsds230daa2; serial: CPU-220-01; tmp: 123;'.encode()) <br/>
                        data = sock.recv(2048) <br/>
                        sock.close()
                    </div>
                    <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>Пример с зашифрованными данными: </span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        sock = socket.socket() <br/>
                        sock.connect(('185.53.22.30', 4640)) <br/>
                        sock.send('Uuid: aca24517-9168-4891-af14-dsdsds230daa2; serial: CPU-220-01; LO\x1f\xc9\x81&gt;gR\xa3Jw\x87'.encode())<br/> 
                        data = sock.recv(2048) <br/>
                        sock.close()
                    </div>
                </div>
            )
        }else{
            return(
                <div className='content custom-auto-margin custom-max-width-responsive is-flex is-flex-direction-column custom-margin-bottom-40'>
                    <span className='title is-size-4 custom-base-font-color custom-font-f custom-devs-container has-text-weight-medium'> 
                        Step 6. Requests in python  
                    </span>
                    <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        Unencrypted data:
                    </span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        sock = socket.socket() <br/>
                        sock.connect(('185.53.22.30', 4640)) <br/>
                        sock.send('Uuid: aca24517-9168-4891-af14-dsdsds230daa2; serial: CPU-220-01; tmp: 123;'.encode()) <br/>
                        data = sock.recv(2048) <br/>
                        sock.close()
                    </div>
                    <span className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-break-space'>
                        Encrypted data:
                    </span>
                    <div className='is-size-6 custom-base-font-color custom-font-f custom-devs-container has-text-weight-normal custom-code-area'>
                        sock = socket.socket() <br/>
                        sock.connect(('185.53.22.30', 4640)) <br/>
                        sock.send('Uuid: aca24517-9168-4891-af14-dsdsds230daa2; serial: CPU-220-01; LO\x1f\xc9\x81&gt;gR\xa3Jw\x87'.encode())<br/> 
                        data = sock.recv(2048) <br/>
                        sock.close()
                    </div>
                </div>
            )
        }
    }
}

function changeInst(d: any){
    d(changeInstruction());
}


function getOwnersData(d: any, lang: any){
    d(reset());
    API.get(
        `api/data/?lang=${lang.id == 0 ? 1 : lang.id}&page=developer`
    ).then(res => {
        d(setData(res.data));
    })
}

function DevsComponent() {
    const devs_items = useSelector((state: RootState) => state.devs);
    const lang = useSelector((state: RootState) => state.header.current_lang);
    const dispatch = useDispatch();

    React.useEffect(
        () => getOwnersData(dispatch, lang), [lang.lang]);
  
    return(
        <>
            <Helmet>
                <title>Unios для разработчиков</title>
                <meta name="description" content="API документация, инструкция по подключению устройств к платформе UNIOS." />
                <meta name="keywords" content="" />
            </Helmet>
            <DefaultOwners {...devs_items}/>
        </>
    )
  }
  
  export default DevsComponent;
