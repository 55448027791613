import { createSlice } from '@reduxjs/toolkit';

import { HeaderM } from '../models/headerModel';

const initialState: HeaderM = {
    header_data: [],
    d_toggle: false,
    current_lang: {
        id: 0,
        lang: ''
    },
    unios_logo: undefined,
    header_main: undefined,
    header_vendors: undefined,
    header_owners: undefined,
    header_services: undefined,
    header_devs: undefined,
    header_news: undefined,
    header_enter: undefined,
    current_page: 1,
    page_width: 0,
    show_mobile_menu: undefined
  }

  function selectField(state: any, obj: any){
    return state[obj.tag] = obj
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setData: (state, action ) => {
        state.header_data = action.payload
        action.payload.map((obj: any) => {
            selectField(state, obj);
        })
    },

    handleToggle: (state) => {
        state.d_toggle = !state.d_toggle;
    },

    setCurrentLang: (state, action) => {
        state.current_lang = action.payload;
    },

    setCurrentPage: (state, action) => {
        state.current_page = action.payload;
    },

    setPageWidth: (state, action) => {
        state.page_width = action.payload;
    },

    showMobileMenu: (state) => {
        state.show_mobile_menu = !state.show_mobile_menu;
    }
  }
});

export const { setData, handleToggle, setCurrentLang, setCurrentPage, setPageWidth, showMobileMenu } = headerSlice.actions;


export default headerSlice.reducer;