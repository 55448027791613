import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import 'bulma/css/bulma.min.css';
import API, { selectURL } from '../app/api';
import  './styles.scss';
import { setData, setCardsData, reset } from '../features/newsSlice';
import { NewsM } from '../models/newsModels';
import news_t from '../static/news_t.png';
import img_place_0 from '../static/img_place_0.png';
import img_place_1 from '../static/img_place_1.png';
import img_place_2 from '../static/img_place_2.png';
import img_place_3 from '../static/img_place_3.png';
import { Link, useLocation } from 'react-router-dom';
import news_title from '../static/news_title.png';
import { Helmet } from "react-helmet";

function DefaultNews(ni: NewsM){
    if(ni.news_data.length > 0){
        return(
            <div>
                <div className='columns is-desktop is-flex custom-bcg-img custom-margin-bottom-60 
                        custom-columns-without-margin' style={{ backgroundImage: `url(${news_title})`, marginTop: '75px'}}>
                    <div className='column custom-auto-margin custom-max-width-responsive is-grid is-align-items-center is-justify-content-flex-start'>
                        <section className='custom-responsive-margin custom-auto-margin custom-max-width-responsive'>
                            <h2 className="title custom-page-title2 custom-base-font-color custom-font-f">{ni.section_one_title.text}</h2>
                            <h3 className="subtitle custom-base-font-color custom-font-f custom-page-title">
                              {ni.section_one_subtitle.text}
                            </h3>
                        </section>
                    </div>
                </div>
                <SectionTwo {...ni}/>
                <SectionThree {...ni}/>
                <SectionFour {...ni}/>
            </div>
        )
    }else{
        return <div></div>
    }
}

function SectionTwo(ni: NewsM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive abc'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color page-content-h2 has-text-weight-normal">{ni.section_two_title.text}</h2>
                    </div>
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width is-multiline custom-auto-margin custom-max-width-responsive custom-margin-bottom-60 '>
                {ni.section_two_raw_content.map((obj: any) => (
                    <div className='column is-one-third' >
                        <h4 className='custom-title custom-font-f custom-base-font-color page-content-h4 custom-without-margin'
                            style={{ backgroundImage: `url(${news_t})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 'left' }}>
                            {obj.title}
                        </h4>
                        <span className='custom-font-f custom-base-font-color has-text-weight-normal custom-txt-with-arrow'>
                            {obj.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

function SectionThree(ni: NewsM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive abc'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color page-content-h2 has-text-weight-normal">{ni.section_three_title.text}</h2>
                    </div>
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width custom-margin-bottom is-desktop custom-auto-margin custom-max-width-responsive'>
                    {ni.section_three_raw_content.map((obj: any, index) => {
                    return index < 4 ? (<div className='column is-one-quarter'>
                            <div className="custom-presentation-card-max-width">
                                <div className="card-image custom-border-radius" style={{ backgroundImage: index === 0 ? `url(${img_place_0})`
                                        : index === 1 ? `url(${img_place_1})` : index === 2 ? `url(${img_place_2})` : `url(${img_place_3})`,
                                    backgroundRepeat: 'no-repeat', backgroundPositionX: 'left', backgroundSize: 'contain' }}>
                                </div>
                                <div className="card-content custom-without-padding">
                                    <div className="media">
                                      <div className="media-content custom-media-content">
                                        <p className="subtitle is-6 custom-base-font-color custom-font-f">{obj.title}</p>
                                      </div>
                                    </div>
                                    <div className="content">
                                        <div className="buttons">
                                            <a href={`${selectURL()}/api/get-file/?filename=${obj.filename}`} className="button is-link custom-back-btn custom-font-f">Скачать</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    )}
            </div>
        </div>
    )
}

function SectionFour(ni: NewsM){
    let c_url = useLocation();
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color custo-font-size-36 has-text-weight-normal">{ni.section_four_title.text}</h2>
                    </div>
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width'>
            <div className='column'>
                    {ni.section_four_raw_content.map((obj: any) => (
                        <Link to={c_url.pathname + obj.news_id}>
                            <div className='content custom-news-hove custom-news-padding'>
                                <h1 className='is-size-5 custom-base-font-color custom-font-f'>{obj.title}</h1>
                                <span className='custom-news-date custom-font-f'>{obj.date}</span>
                                <p className='is-clipped custom-text-clipping custom-base-font-color custom-font-f'>{obj.text}</p>
                            </div>
                        </Link>
                    ))}
                </div>    
            </div>
        </div>
    )
}

function getNewsData(d: any, lang: any){
    d(reset());
    API.get(
        `api/data/?lang=${lang.id == 0 ? 1 : lang.id}&page=news`
    ).then(res => {
        d(setData(res.data));
    })
    
    API.get(
        `api/cardata/?lang=${lang.id == 0 ? 1 : lang.id}&page=news`
    ).then(res => {
        d(setCardsData(res.data));
    })

    API.get(
        `api/presentation/?lang=${lang.id == 0 ? 1 : lang.id}&page=news`
    ).then(res => {
        d(setCardsData(res.data));
    })

    API.get(
        `api/all-news/?lang=${lang.id == 0 ? 1 : lang.id}&page=news`
    )
        .then(res => {
            console.log(res);
            return res;
        })
        .then(res => {
        d(setCardsData([...res.data.reverse()]));
    })
}

function NewsComponent() {
    const news_items = useSelector((state: RootState) => state.news);
    const lang = useSelector((state: RootState) => state.header.current_lang);
    const dispatch = useDispatch();

    React.useEffect(
        () => getNewsData(dispatch, lang), []);
  
    return(
        <>
            <Helmet>
                <title>Unios о нас</title>
                <meta name="description" content="команда Unios, документы, новости." />
                <meta name="keywords" content="" />
            </Helmet>
            <DefaultNews {...news_items}/>
        </>
    )
  }
  
  export default NewsComponent;
