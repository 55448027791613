import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import 'bulma/css/bulma.min.css';
import API from '../app/api';
import { setData } from '../features/footerSlice';
import  './styles.scss';
import { Footer } from '../models/footerModel';
import phone from '../static/phone.png';
import mail from '../static/mail.png';
import black_arrow from '../static/black-arrow.png';
import youtube from '../static/youtube.png'
import insta from '../static/insta.png';
import ftxlogo from '../static/ftxlogo.png'
import { Link } from 'react-router-dom';
import { setCurrentPage } from '../features/headerSlice';
import footer_man from '../static/footer_man.png';
import gasprom from '../static/gasprom.png';
import hodap from '../static/hodap.png';
import x5 from '../static/x5.png';
import sap from '../static/sap.png';

function SectionFive(fi: Footer){
    return(
        <div className='columns is-multiline is-centered custom-m-top custom-auto-margin custom-max-width-responsive'>
            {/* Section Five */}
            <div className='column is-half is-flex is-flex-direction-column'>
                <h4 className='custom-font-f has-text-centered custo-font-size-36 custom-base-font-color custom-margin-bottom-31'>{fi.section_five_title.text}</h4>
                <span className='is-size-6 custom-max-width-500 custom-font-f has-text-centered custom-base-font-color custom-margin-bottom-55'>{fi.section_five_subtitle.text}</span>
                <div className='columns is-mobile is-vcentered is-flex is-justify-content-center'>
                    <div className='column is-flex is-flex-direction-column is-align-items-center'>
                        <a className="custom-m-width-137 button custom-base-font-color custom-button-border" href="https://go.unios.io/ng/signup">{fi.section_five_btn.text}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DefaultFooter(fi: Footer, d:any){
    if(fi.footer_data.length > 0){
        return(
        <div>
            <SectionFive {...fi}/>
            <footer className="footer custo-footer-margin">
                <div className='is-relative'>
                    <img src={footer_man} className='custom-footer-man' />
                </div>
                <div className='content is-flex custom-footer-margin is-justify-content-space-between custom-auto-margin custom-max-width-responsive'>
                    <div className='content is-flex is-flex-direction-column'>
                        <div className='content custom-footer-unios-logo'>
                            <img src={ftxlogo} className='custo-foter-margin-left custom-footer-logo-max-height'/>
                        </div>
                        <div className='content custom-margin-bottom-16'>
                            <img src={phone} className='custom-footer-icons' />
                            <span>{fi.footer_phone.text}</span>
                        </div>
                        <div className='content'>
                            <img src={mail} className='custom-footer-icons' />
                            <span>{fi.footer_email.text}</span>
                        </div>
                        <a href="https://go.unios.io/ng/login" className='custom-margin-bottom-16'>
                            <div className='content'>
                                <img src={black_arrow} className='custom-foter-margin-right' />
                                <span className='custom-base-font-color'>{fi.footer_enter.text}</span>
                            </div>
                        </a>
                        <a href="https://go.unios.io/ng/signup" className='custom-margin-bottom-16'>
                            <div className='content'>
                                <img src={black_arrow} className='custom-foter-margin-right' />
                                <span className='custom-base-font-color'>{fi.footer_reg.text}</span>
                            </div>
                        </a>
                        <div className='content'>
                            <img src={youtube} className='custom-foter-margin-right' />
                            <img src={insta} />
                        </div>
                        <div className='content'>
                            <span>&#169; {fi.footer_ftx.text}</span>
                        </div>
                    </div>
                    <Partners {...fi}/>
                    <div className='content'>
                        <Link to='/' onClick={() => d(setCurrentPage(1))}>
                            <div className='content is-align-items-center is-justify-content-flex-end is-flex  custom-margin-bottom-16 custom-base-font-color custom-line-h-16 custom-footer-padding-top-134'>
                                <span>{fi.footer_main.text}</span>
                                <img src={black_arrow} className='custom-img-height-8 custom-reverse-img custo-foter-margin-left'/>
                            </div>
                        </Link>
                        <Link to='vendors/' onClick={() => d(setCurrentPage(2))}>
                            <div className='content is-align-items-center is-justify-content-flex-end is-flex  custom-margin-bottom-16 custom-base-font-color custom-line-h-16'>
                                <span>{fi.footer_vendor.text}</span>
                                <img src={black_arrow} className='custom-img-height-8 custom-reverse-img custo-foter-margin-left'/>
                            </div>
                        </Link>
                        <Link to='owners/' onClick={() => d(setCurrentPage(3))}>
                            <div className='content is-align-items-center is-justify-content-flex-end is-flex  custom-margin-bottom-16 custom-base-font-color custom-line-h-16'>
                                <span>{fi.footer_owner.text}</span>
                                <img src={black_arrow} className='custom-img-height-8 custom-reverse-img custo-foter-margin-left'/>
                            </div>
                        </Link>
                        <Link to='service/' onClick={() => d(setCurrentPage(4))}>
                            <div className='content is-align-items-center is-justify-content-flex-end is-flex  custom-margin-bottom-16 custom-base-font-color custom-line-h-16'>
                                <span>{fi.footer_service.text}</span>
                                <img src={black_arrow} className='custom-img-height-8 custom-reverse-img custo-foter-margin-left'/>
                            </div>
                        </Link>
                        <Link to='news/' onClick={() => d(setCurrentPage(6))}>
                            <div className='content is-align-items-center is-justify-content-flex-end is-flex  custom-margin-bottom-16 custom-base-font-color custom-line-h-16'>
                                <span>{fi.footer_about.text}</span>
                                <img src={black_arrow} className='custom-img-height-8 custom-reverse-img custo-foter-margin-left'/>
                            </div>
                        </Link>
                    </div>
                </div>
            </footer>
        </div>    
            )
    }else{
        return <div></div>
    }
}

function NarrowFooter(fi: Footer, d:any){
    if(fi.footer_data.length > 0){
        return(
        <div>
            <SectionFive {...fi}/>
            <footer className="footer custo-footer-margin">
                <div className='content is-relative'>
                    <img src={footer_man} className='custom-footer-man' />
                </div>
                <div className='content is-flex custom-footer-margin is-justify-content-space-between custom-auto-margin custom-max-width-responsive'>
                    <div className='content is-flex is-flex-direction-column'>
                        <div className='content custom-footer-unios-logo'>
                            <img src={ftxlogo} className='custo-foter-margin-left custom-margin-right-70 custom-footer-logo-max-height'/>
                            <a href="https://www.youtube.com/channel/UC1A0DPxkyG8UHPcwY4t3GGw">
                                <img src={youtube} className='custom-foter-margin-right custom-footer-iconsyi' />
                            </a>
                            <a href="">
                                <img src={insta} className='custom-footer-iconsyi'/>
                            </a>
                        </div>
                        <div className='content custom-margin-bottom-16'>
                            <img src={phone} className='custom-footer-icons' />
                            <span>{fi.footer_phone.text}</span>
                        </div>
                        <div className='content'>
                            <img src={mail} className='custom-footer-icons' />
                            <span>{fi.footer_email.text}</span>
                        </div>
                        <a href="https://go.unios.io/ng/login" className='custom-margin-bottom-16'>
                            <div className='content'>
                                <img src={black_arrow} className='custom-foter-margin-right' />
                                <span className='custom-base-font-color'>{fi.footer_enter.text}</span>
                            </div>
                        </a>
                        <a href="https://go.unios.io/ng/signup" className='custom-margin-bottom-16'>
                            <div className='content'>
                                <img src={black_arrow} className='custom-foter-margin-right' />
                                <span className='custom-base-font-color'>{fi.footer_reg.text}</span>
                            </div>
                        </a>
                        <Link to='/' onClick={() => d(setCurrentPage(1))}>
                            <div className='content custom-margin-bottom-16 custom-base-font-color custom-line-h-16'>
                                <img src={black_arrow} className='custom-foter-margin-right'/>
                                <span>{fi.footer_main.text}</span>
                            </div>
                        </Link>
                        <Link to='vendors/' onClick={() => d(setCurrentPage(2))}>
                            <div className='content custom-margin-bottom-16 custom-base-font-color custom-line-h-16'>
                                <img src={black_arrow} className='custom-foter-margin-right'/>
                                <span>{fi.footer_vendor.text}</span>
                            </div>
                        </Link>
                        <Link to='owners/' onClick={() => d(setCurrentPage(3))}>
                            <div className='content custom-margin-bottom-16 custom-base-font-color custom-line-h-16'>
                                <img src={black_arrow} className='custom-foter-margin-right'/>
                                <span>{fi.footer_owner.text}</span>
                            </div>
                        </Link>
                        <Link to='service/' onClick={() => d(setCurrentPage(4))}>
                            <div className='content custom-margin-bottom-16 custom-base-font-color custom-line-h-16'>
                                <img src={black_arrow} className='custom-foter-margin-right'/>
                                <span>{fi.footer_service.text}</span>
                            </div>
                        </Link>
                        <Link to='news/' onClick={() => d(setCurrentPage(6))}>
                            <div className='content custom-margin-bottom-16 custom-base-font-color custom-line-h-16'>
                                <img src={black_arrow} className='custom-foter-margin-right'/>
                                <span>{fi.footer_about.text}</span>
                            </div>
                        </Link>
                        <div className='content'>
                            <span>&#169; {fi.footer_ftx.text}</span>
                        </div>
                    </div>
                </div>
            </footer>
        </div>    
            )
    }else{
        return <div></div>
    }
}

function Partners(fi: Footer){
    const page_width = useSelector((state: RootState) => state.header.page_width);
    if(page_width >= 1024){
        return (
            <div className='content is-flex is-justify-content-space-between is-align-items-baseline custom-flex-50'
                    style={{display: page_width > 1024 ? '' : 'none!important'}}>
                        <img src={gasprom}/>
                        <img src={hodap}/>
                        <img src={x5}/>
                        <img src={sap}/>
            </div>
        )
    }else{
        return <div></div>
    }
}

function getFooterData(d: any, lang: any){
    API.get(`api/data/?lang=${lang.id}&page=footer`).then(
        res => {
            d(setData(res.data));
        }
    )
}

function FooterComponent() {
    const footer_items = useSelector((state: RootState) => state.footer);
    const page_width = useSelector((state: RootState) => state.header.page_width);
    const lang = useSelector((state: RootState) => state.header.current_lang);
    const dispatch = useDispatch();

    React.useEffect(
        () => getFooterData(dispatch, lang), [lang.lang]
    )
    
    if(page_width >= 480){
        return (
            <DefaultFooter {...footer_items} {...dispatch}/>
        );
    }else{
        return (
            <NarrowFooter {...footer_items} {...dispatch}/>
        );
    }
  }
  
  export default FooterComponent;