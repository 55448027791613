import React from 'react';
import MainPageComponent  from '../src/components/MainPageComponent';
import VendorsComponent from '../src/components/VendorsComponent';
import './App.css';
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";
import Header from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import OwnersComponent from './components/OwnersCpmponent';
import ServiceComponent from './components/ServiceComponent';
import NewsComponent from './components/NewsComponent';
import OneNewsComponent from './components/OneNewsComponent';
import DevsComponent from './components/DevsComponent';

function App() {
  return (
    <BrowserRouter>
        <Header />
        <Routes>
            <Route path="/" element={<MainPageComponent />} />
            <Route path="vendors" element={<VendorsComponent />}/>
            <Route path="owners" element={<OwnersComponent />} />
            <Route path="service" element={<ServiceComponent />}/>
            <Route path="for-developers" element={<DevsComponent />}/>
            <Route path="news" element={<NewsComponent />}/>
            <Route path="news/:id" element={<OneNewsComponent />}/>
        </Routes>
        <FooterComponent />
  </BrowserRouter>
  );
}

export default App;
