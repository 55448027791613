import { createSlice } from '@reduxjs/toolkit';

import { OneNewsM } from '../models/oneNewsModel';

const initialState: OneNewsM = {
    text: undefined,
    title: undefined,
    date: undefined,
    file: undefined,
    btn: undefined,
    filename: undefined
  }

export const oneNewsSlice = createSlice({
  name: 'one_news',
  initialState,
  reducers: {
    setData: (state, action ) => {
        state.text = action.payload[0].text;
        state.title = action.payload[0].title;
        state.date = action.payload[0].date;
        state.file = action.payload[0].file;
        state.btn = action.payload[0].btn;
        state.filename = action.payload[0].filename;
    },

    reset: () => initialState
  }
});

export const { setData, reset } = oneNewsSlice.actions;


export default oneNewsSlice.reducer;