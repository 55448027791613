import { createSlice } from '@reduxjs/toolkit';

import { DevsM } from '../models/devsModel';

const initialState: DevsM = {
    devs_data: [],
    section_one_title: undefined,
    section_one_subtitle: undefined,
    section_one_img: undefined,

    section_two_title: undefined,
    section_two_content: undefined,

    section_three_title: undefined,
    section_three_content: undefined,

    section_four_title: undefined,
    section_four_content: undefined,

    section_five_title: undefined,
    section_five_content: undefined,

    first_href: undefined,
    second_href: undefined,

    rest_api: true
  }

  function selectField(state: any, obj: any){
    return state[obj.tag] = obj
}

export const devsSlice = createSlice({
  name: 'devs',
  initialState,
  reducers: {
    setData: (state, action ) => {
        state.devs_data = action.payload
        action.payload.map((obj: any) => {
                selectField(state, obj);
        })
    },

    changeInstruction: (state) => {
        state.rest_api = !state.rest_api;
    },

    reset: () => initialState
  }
});

export const { setData, changeInstruction, reset } = devsSlice.actions;


export default devsSlice.reducer;