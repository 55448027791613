import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import 'bulma/css/bulma.min.css';
import API from '../app/api';
import  './styles.scss';
import { VendorsM } from '../models/vendorsModel';
import { setData, setCardsData, reset } from '../features/vendorsSlice';
import arrow from '../static/arrow.svg';
import vendors_title from '../static/vendors_title.svg';
import vendors_bcg from '../static/vendors_bcg.png';
import { Helmet } from "react-helmet";

function DefaultVendors(vi: VendorsM){
    if(vi.vendors_data.length > 0){
        return(
            <div>
                <div className='columns is-desktop is-flex custom-bcg-img custom-margin-bottom-60 
                        custom-columns-without-margin' style={{ backgroundImage: `url(${vendors_bcg})`, marginTop: '75px' }}>
                    <div className='column custom-auto-margin custom-max-width-responsive is-grid is-align-items-center is-justify-content-flex-start'>
                        <section className='custom-responsive-margin custom-auto-margin custom-max-width-responsive'>
                            <h2 className="title custom-page-title2 custom-base-font-color custom-font-f">{vi.section_one_title.text}</h2>
                            <h3 className="subtitle custom-base-font-color custom-font-f custom-page-title">
                              {vi.section_one_subtitle.text}
                            </h3>
                        </section>
                    </div>
                </div>
                <SectionTwo {...vi}/>
                <SectionThree {...vi}/>
            </div>
        )
    }else{
        return <div></div>
    }
}

function SectionTwo(vi: VendorsM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive abc'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color custo-font-size-36 has-text-weight-normal">{vi.section_two_title.text}</h2>
                    </div>
                    {vi.section_two_column_content.map((obj: any) => (
                        <div className='content is-flex custom-position-relative'>
                            <img className='custom-blue-arrow' src={arrow} />
                            <span className='custom-base-font-color custom-font-f'>{obj.text}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width custom-margin-top-70 is-multiline'>
                {vi.section_two_raw_content.map((obj: any) => (
                    <div className='column is-one-third'>
                        <h4 className='custom-title custom-base-font-color custom-font-f' 
                                style={{ backgroundImage: `url(${vendors_title})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 'left' }}>
                            {obj.title}
                        </h4>
                        <span className='custom-base-font-color custom-font-f'>
                            {obj.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

function SectionThree(vi: VendorsM){
    return(
        <div>
            <div className='columns custom-vendors-content-max-width custom-auto-margin custom-max-width-responsive'>
                <div className='column'>
                    <div className='content'>
                        <h2 className="custom-without-margin custom-font-f custom-base-font-color custo-font-size-36 has-text-weight-normal">{vi.section_three_title.text}</h2>
                    </div>
                    {vi.section_three_column_content.map((obj: any) => (
                        <div className='content is-flex custom-position-relative'>
                            <img className='custom-blue-arrow' src={arrow} />
                            <span className='custom-base-font-color custom-font-f'>{obj.text}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='columns custom-vendors-content-max-width custom-margin-top-70 custom-margin-bottom is-multiline'>
                {vi.section_three_raw_content.map((obj: any) => (
                    <div className='column is-one-third'>
                        <h4 className='custom-title custom-base-font-color custom-font-f' style={{ backgroundImage: `url(${vendors_title})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 'left' }}>
                            {obj.title}
                        </h4>
                        <span className='custom-base-font-color custom-font-f'>
                            {obj.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

function getVendorsData(d: any, lang: any){
    d(reset());
    API.get(
        `api/data/?lang=${lang.id == 0 ? 1 : lang.id}&page=vendor`
    ).then((res: any) => {
        d(setData(res.data));
    })
    
    API.get(
        `api/cardata/?lang=${lang.id == 0 ? 1 : lang.id}&page=vendor`
    ).then((res: any) => {
        d(setCardsData(res.data));
    })
}

function VendorsComponent() {
    const vendor_items = useSelector((state: RootState) => state.vendors);
    const lang = useSelector((state: RootState) => state.header.current_lang);
    const dispatch = useDispatch();

    React.useEffect(
        () => getVendorsData(dispatch, lang), [lang.lang]);
  
    return(
        <>
            <Helmet>
            <title>Unios - Сервис для подключения производителей к IoT</title>
            <meta name="description" content="Доступ к сервисам Unios: автодиагностика оборудования, поиск ближайших компетентных сервисов, поиск комплектующих на ближайшем складе, предиктивный анализ событий, интерактивные карты размещения оборудования на объекте." />
            <meta name="keywords" content="" />
            </Helmet>
            <DefaultVendors {...vendor_items}/>
        </>
    )
  }
  
  export default VendorsComponent;
